export const sigcapAddress = "0xafc7d0FA7718496b0E1bb76A068c34FcCcB470E2";
export const espgoerli = {
	router: "0x44Fec79BaA6f6f9865bc61CB3566b56A57679A4e",
	toplevelsystem: "0x1Ee7B1baAAC58b05c84EBE42aBFf6bDE5Aa504d9",
	memberpointsregistry: "0x104D7e73ba2CF955A813da67c37f5631aFc89619"
};
export const espgnosis = {
	router: "0x2E37A0CFbe92Cd27372Ac2E2aA60043d7c92Bdbd",
	toplevelsystem: "0x4D014759620Ba3Fd00BBe852F271e729d0581009",
	memberpointsregistry: "0x734c0f29B20b63ecE7e0B9e672707e4C5aFD6A82",
	streamownerregistry: "0x3f15161C446F34E61B6885A781AA75772BF871D4"
};
